import './App.css';

function Loader() {
  return (
    <svg width="1em" height="1em">
      <g><path d="M 50,100 A 1,1 0 0 1 50,0"/></g>
      <g><path d="M 50,75 A 1,1 0 0 0 50,-25"/></g>
    </svg>
  );
}

export default Loader;