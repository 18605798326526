import { useState } from "react";
import { getFirestore, collection, addDoc, doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import "./App.css";

function createId() {
  return Math.random().toString(36).slice(-5);
}

function Shorturl() {
  const [inputUrl, setInputUrl] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [validUrl, setValidUrl] = useState(false);

  // Validate URL
  function validateLink() {
    let urlRegEx =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    return urlRegEx.test(inputUrl);
  }

  // Write url to DB.
  async function addLinkItem() {
    if (validateLink()) {
      setValidUrl(false);
      console.log('URL = 👍')
      const miniUrlID = createId();
      const data = { link: inputUrl };

      await setDoc(doc(db, "links", miniUrlID), data)
        .then(() => {
          setGeneratedUrl(`url.xcode.no/${miniUrlID}`);
          // copyToClipboard()
        })
        .catch(error => {
          console.error("Error adding document: ", error);
        })
    } else {
      console.error("URL = 🚫");
      setValidUrl(true);
    }
  }

  function shortenUrl(e) {
    addLinkItem();
  }
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      shortenUrl();
    }
  }
  return (
    <>
      <div className="inputBtnContainer">
        <input
          className="cstmInput"
          onInput={(e) => setInputUrl(e.target.value)}
          onKeyPress={handleKeyPress}
          type="text"
          name="shorturl"
          id="shorturl"
          placeholder="Shorten your link"
        />
        <button onClick={shortenUrl} className="basicdBtn cstmBtnGo">
          GO
        </button>
      </div>
      <br />
      {generatedUrl && (
        <>
          <div className="resultsContainer">
            <span style={{ fontWeight: "600" }}>{generatedUrl}</span>
            <button
              className="basicdBtn cstmBtnCopy"
              onClick={() => {
                navigator.clipboard.writeText(generatedUrl);
              }}
            >
              COPY
            </button>
          </div>
        </>
      )}
      {validUrl && (
        <p>
          🚫 🙅‍♀️ Unable to shorten link. <br />
          Enter a valid url.
        </p>
      )}
    </>
  );
}

export default Shorturl;
