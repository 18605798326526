import { useEffect, useState } from 'react'
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import './App.css';
import Shorturl from './Shorturl'
import Loader from './Loader'

function App() {
  const urlLoc = window.location.href
  const newUrl = /[^/]*$/.exec(urlLoc)[0]; // FIXME, enable this
  const [showLoader, setShowLoader] = useState(true)
  const [globalRedirectUrl, setGlobalRedirectUrl] = useState('')


  // Add http or https to url (if not redirect does not work)
  const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  // Redirect
  useEffect(() => {
    if (newUrl) {
      setShowLoader(true)
      getLink(newUrl)
      window.history.pushState("", "", '/'); // Fjerner «/xx» fra url.
    } else {
      setShowLoader(false)
    }
  }, [])

  // NOTE: test url: b01ii

  async function getLink(urlID) {
    try {
      const docRef = doc(db, "links", urlID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const tempUrl = docSnap.data().link;
        setGlobalRedirectUrl(tempUrl);
        tempUrl !== undefined ? window.location.replace(withHttp(tempUrl)) : console.error('Error');
        setShowLoader(false);
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  }

  return (
    <>
      {showLoader &&
        <>
          <div className="loaderContainer">
            <Loader />
            <div className="loaderTextCont">
              <p>{globalRedirectUrl && globalRedirectUrl}</p>
            </div>
          </div>
        </>
      }
      {!showLoader &&
        <div className="App">
          <main>
            <h1>URL <br /> Shortener</h1>
            <div className="lineBlock"></div>
            <Shorturl />
          </main>
          <footer>
            <p>by <a href="https://www.xcode.no" rel="noreferrer noopener" target="_blank">xCode</a></p>
          </footer>
        </div>
      }
    </>
  );
}

export default App;